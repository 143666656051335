import './assets-loader';
import './polyfills';
import './vendor/cookie-modal';
import './service-worker';

import ScrollOut from "scroll-out";
import Headroom from "headroom.js";
import * as cookieModal from './components/cookie-modal';
import * as menu from './components/menu';
import * as lightbox from './components/lightbox';

ScrollOut({
  once: true,
});

const headroomNavbar = document.querySelector(".js-headroom");
const headroom = new Headroom(headroomNavbar, {
  "offset": 0,
  "tolerance": 0,
});

headroom.init();
cookieModal.init();
menu.init();
lightbox.init();
