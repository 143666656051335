export const init = () => {
  const $open = document.querySelectorAll('.js-lightbox-open');
  const $close = document.querySelectorAll('.js-lightbox-close');
  const $lightbox = document.querySelector('#js-lightbox');
  const $lightboxVideo = document.querySelector('#js-lightbox-video');

  Array.prototype.forEach.call($open, $element =>
    $element.addEventListener('click', () => {
      $lightboxVideo.firstElementChild.src = $element.getAttribute('data-lightbox-video');
      $lightboxVideo.load();
      $lightbox.classList.add('js-active');
      $lightboxVideo.play();
    })
  );

  Array.prototype.forEach.call($close, $element =>
    $element.addEventListener('click', () => {
      $lightboxVideo.pause();
      $lightbox.classList.remove('js-active');
      $lightboxVideo.firstElementChild.src = '';
    })
  );
};
