/**
 * Triggers event listener once - fix for IE till version 11 and iOS till version 10
 *
 * @param {HTMLElement} $element
 * @param {string} type
 * @param {function} func
 * @returns {void}
 */
export const addEventListenerOnce = ($element, type, func) => {
    const handler = (event) => {
        $element.removeEventListener(type, handler);
        func(event);
    };

    $element.addEventListener(type, handler);
};

/**
 * Triggers a custom event
 *
 * @param {HTMLElement} $ref
 * @param {string} event
 * @param {string} detail
 */
export const dispatchEvent = ($ref, event, detail = '') => {
    $ref.dispatchEvent(
        new CustomEvent(event, {
            detail,
        })
    );
};
