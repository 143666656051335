export const init = () => {
  const $site = document.querySelector('#site');

  if ($site) {
    const $menuToggle = document.querySelectorAll('.js-toggle-menu');

    Array.prototype.forEach.call($menuToggle, $element =>
      $element.addEventListener('click', () => {
        $site.classList.toggle('js-menu-is-active');
        $element.blur();
      })
    );
  }
};
