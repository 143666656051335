export const init = () => {
  const $cookieToggles = document.querySelectorAll('.js-cookie-settings');

  Array.prototype.forEach.call($cookieToggles, $element =>
    $element.addEventListener('click', () => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('cookies:update', true, false);
      document.querySelector('body').dispatchEvent(event);
    }
  ));

  const $cookieButtons = document.querySelectorAll('.cookie-modal__button');

  Array.prototype.forEach.call($cookieButtons, $element =>
    $element.addEventListener('click', () => {
      location.reload();
    }
  ));
};
