import htmlToElement from 'html-to-element';
import { addEventListenerOnce } from './event-listener';

const _defaultOptions = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        transform: 'translateY(-400%)',
        transition: 'transform .5s ease-in-out',
        width: '100%',
        willChange: 'transform',
        zIndex: 100
    },
    message: {
        backgroundColor: '#000000',
        maxWidth: '40%',
        padding: '2rem 4rem 2rem 4rem'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        marginRight: '1rem',
        marginTop: '1rem'
    },
    options: {
        timeout: 3000
    }
};

let mergedOptions = {};
let globalFunc = () => {};
let $toast = null;
let $wrapper = null;
let $message = null;
let $buttons = null;

/**
 * Converts camel case to kebab case
 *
 * @param string
 * @returns {string}
 */
const camelToKebab = string => {
    return string
        .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
        .toLowerCase();
};

/**
 * Converts options to style attributes
 *
 * @param options
 * @returns {string}
 */
const createStyling = options => {
    let styling = '';

    Object.keys(options).forEach(key => {
        styling += `${camelToKebab(key)}: ${options[key]};`;
    });

    return styling;
};

/**
 * Initialize the toast
 *
 * @param options
 * @returns {void}
 */
export const init = (options = { wrapper: {}, message: {}, options: {} }) => {
    mergedOptions = {
        wrapper: {
            ..._defaultOptions.wrapper,
            ...options.wrapper
        },
        message: {
            ..._defaultOptions.message,
            ...options.message
        },
        buttons: {
            ..._defaultOptions.buttons,
            ...options.buttons
        },
        button: {
            ..._defaultOptions.button,
            ...options.button
        },
        options: {
            ..._defaultOptions.options,
            ...options.options
        }
    };

    const toastStyle = createStyling(mergedOptions.wrapper);
    const messageStyle = createStyling(mergedOptions.message);
    const buttonsStyle = createStyling(mergedOptions.buttons);

    $toast = htmlToElement(`<div class="toast" style="${toastStyle}"></div>`);
    $wrapper = htmlToElement(
        `<div class="toast__message__wrapper" style="${messageStyle}"></div>`
    );
    $message = htmlToElement(`<div class="toast__message"></div>`);
    $buttons = htmlToElement(
        `<div class="toast__buttons" style="${buttonsStyle}"></div>`
    );

    // noinspection JSCheckFunctionSignatures
    $wrapper.appendChild($message);
    // noinspection JSCheckFunctionSignatures
    $wrapper.appendChild($buttons);
    // noinspection JSCheckFunctionSignatures
    $toast.appendChild($wrapper);
    // noinspection JSCheckFunctionSignatures
    document.body.appendChild($toast);
};

/**
 * Shows the toast
 *
 * @returns {void}
 */
const show = centered => {
    let position = centered
        ? `${window.innerHeight / 2 - $toast.offsetHeight / 2}px`
        : 0;

    $toast.style.transform = `translateY(${position})`;
};

/**
 * Hides the toast
 *
 * @returns {void}
 */
const hide = () => {
    $toast.style.transform = 'translateY(-100%)';

    addEventListenerOnce($toast, 'transitionend', () => {
        $message.innerText = '';
        $buttons.innerText = '';

        globalFunc();
    });
};

/**
 *
 * @param buttons
 * @returns {void}
 */
const getButtons = buttons => {
    const buttonCount = buttons.length;
    let i = 0;

    buttons.forEach(button => {
        const $button = htmlToElement(
            `<button class="toast__buttons__button ${
                button.classList
            }" style="${createStyling(mergedOptions.button)}">${
                button.label
            }</button>`
        );

        if (typeof button.func === 'function') {
            $button.addEventListener('click', () => {
                button.func();

                if (button.changeTextAfterFunc !== '') {
                    $button.innerHTML = button.changeTextAfterFunc;

                    setTimeout(() => {
                        $button.innerHTML = button.label;
                    }, 2000);
                }
            });
        }

        if (button.type === 'close') {
            $button.addEventListener('click', () => hide());
        }

        if (buttonCount - 1 === i) {
            $button.style.marginRight = '0';
        }

        $buttons.appendChild($button);
        i++;
    });
};

/**
 * Checks if the image can be loaded
 *
 * @param path
 * @returns {Promise<unknown>}
 */
const checkImage = path =>
    new Promise(resolve => {
        const img = new Image();
        img.onload = () => resolve({ path, status: 'ok' });
        img.onerror = () => resolve({ path, status: 'error' });

        img.src = path;
    });

/**
 * Adds a message to the queue
 *
 * @param message
 * @param func
 * @param options
 * @returns {void}
 */
export const add = (
    message = '',
    func = () => {},
    options = {
        noTimeOut: false,
        centered: false,
        buttons: []
    }
) => {
    if (typeof func === 'function') {
        globalFunc = func;
    }

    $message.innerHTML = message;
    getButtons(options.buttons);

    const $preloadingImage = $message.querySelectorAll('img');

    if ($preloadingImage.length > 0) {
        let path = [];
        Array.prototype.forEach.call($preloadingImage, $image =>
            path.push($image.src)
        );

        const loadImg = (...paths) => Promise.all(paths.map(checkImage));

        loadImg(path).then(() => show(options.centered));
    } else {
        show(options.centered);
    }

    if (!options.noTimeOut) {
        setTimeout(() => hide(), mergedOptions.options.timeout);
    }
};
